import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // elige el almacenamiento (localstorage por defecto)
import workspaceReducer from "./workspace_reducer";
import authReducer from "./auth_reducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  workspace: workspaceReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;

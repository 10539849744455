const initialState = {
  workspaceData: null, // Inicializar con null o un objeto vacío
};

const workspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_WORKSPACE_DATA":
      return {
        ...state,
        workspaceData: action.payload,
      };
    case "SAVE_WORKSPACE_DATA":
      return {
        ...state,
        workspaceData: action.payload,
      };
    default:
      return state;
  }
};

export default workspaceReducer;

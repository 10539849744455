import { db } from "./config";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";
// import { limpiarRut } from "../utils/functions";

async function getUserById(userId) {
  // console.log("Buscando por id", userId);
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
}

// async function getUserByRut(rutUser) {
//   let rut = limpiarRut(rutUser);
//   let usersArr = [];
//   const queryData = query(collection(db, "users"), where("rut", "==", rut));
//   const users = await getDocs(queryData);
//   users.docs.map((doc) => {
//     usersArr.push({
//       id: doc.id,
//       ...doc.data(),
//     });
//   });
//   return usersArr;
// }

async function getUserByEmail(email) {
  let usersArr = [];
  const queryData = query(collection(db, "users"), where("email", "==", email));
  const users = await getDocs(queryData);
  users.docs.map((doc) => {
    usersArr.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return usersArr;
}

async function uploadImage(file) {
  const storageRef = firebase.storage().ref();
  const imageRef = storageRef.child(`secretaries/${file.name}-${Date.now()}`);

  try {
    // Sube la imagen a Firebase Storage
    await imageRef.put(file);

    // Obtiene la URL de descarga de la imagen
    const url = await imageRef.getDownloadURL();

    // Retorna la URL de descarga
    return url;
  } catch (error) {
    console.error(error);
    return false;
  }
}
async function createUser(params) {
  try {
    const {
      uid,
      nickname,
      email,
      status = "active",
      firstTime = true,
    } = params;

    // Valida que los parámetros necesarios estén presentes
    if (!uid || !nickname || !email) {
      throw new Error("Faltan datos necesarios para crear el usuario.");
    }

    // Crea un documento en la colección 'users' con el ID del usuario
    const userDoc = doc(db, "users", uid);
    await setDoc(userDoc, {
      nickname,
      email,
      status,
      firstTime,
      createdAt: new Date().toISOString(), // Fecha de creación
    });

    console.log("Usuario creado correctamente:", nickname);
    return true;
  } catch (error) {
    console.error("Error al crear usuario:", error);
    return false;
  }
}

export { getUserById, getUserByEmail, createUser };

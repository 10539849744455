import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { auth } from "../database/config";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonIcon from "@mui/icons-material/Person";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { createUser } from "../database/users";

export default function Register() {
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleRegister = async () => {
    if (!nickname || !email || !password) {
      Swal.fire({
        icon: "info",
        title: "Campos incompletos",
        text: "Por favor, completa todos los campos.",
      });
      return;
    }

    if (password.length < 6) {
      Swal.fire({
        icon: "info",
        title: "Contraseña débil",
        text: "La contraseña debe tener al menos 6 caracteres.",
      });
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(userCredential.user);
      await createUser({
        uid: userCredential.user.uid,
        nickname,
        email,
        status: "active",
        firstTime: true,
      });

      Swal.fire({
        icon: "success",
        title: "Registro completado",
        text: "Se ha enviado un correo de verificación. Por favor revisa tu bandeja de entrada o spam.",
      });
      navigate("/login");
    } catch (error) {
      console.error("Error durante el registro:", error);
      Swal.fire({
        icon: "error",
        title: "Error en el registro",
        text: "No se pudo completar el registro. Por favor, inténtalo de nuevo más tarde.",
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        backgroundColor: "#1f1f1f",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          maxWidth: "600px",
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: { xs: "2rem", md: "3rem" },
              backgroundColor: "gray",
              borderRadius: "1.75rem",
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{ marginBottom: "1rem", fontWeight: "bold" }}
            >
              Crear Cuenta
            </Typography>
            <Box component="form" noValidate sx={{ width: "100%" }}>
              <TextField
                fullWidth
                required
                variant="outlined"
                label="Nickname"
                placeholder="Tu apodo"
                margin="normal"
                autoFocus
                onChange={(e) => setNickname(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                required
                variant="outlined"
                label="Correo Electrónico"
                placeholder="ejemplo@correo.com"
                margin="normal"
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                required
                variant="outlined"
                label="Contraseña"
                placeholder="Tu contraseña"
                type="password"
                margin="normal"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={handleRegister}
                sx={{
                  mt: 2,
                  mb: 2,
                  height: "45px",
                  backgroundColor: "#1dff00",
                  color: "#1f1f1f",
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor: "#00e600",
                  },
                }}
              >
                Registrarse
              </Button>
              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                ¿Ya tienes una cuenta?{" "}
                <Button
                  onClick={() => navigate("/login")}
                  sx={{
                    textTransform: "none",
                    padding: "0",
                    fontWeight: "bold",
                  }}
                >
                  Iniciar Sesión
                </Button>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

import { doc, setDoc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "./config";

export const saveUserSheet = async (userId, sheetId, { title, content }) => {
  try {
    const userDocRef = doc(db, "workspaces", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      // Si el documento del usuario existe, intentamos acceder a las hojas
      let hojas = userDoc.data().hojas || [];

      // Buscamos si la hoja ya existe
      const existingSheetIndex = hojas.findIndex(
        (hoja) => hoja.idhoja === sheetId
      );

      if (existingSheetIndex !== -1) {
        // Si la hoja ya existe, actualiza el contenido
        hojas[existingSheetIndex].content = content;
        hojas[existingSheetIndex].title = title;
        await updateDoc(userDocRef, { hojas });
      } else {
        // Si la hoja no existe, agregamos una nueva hoja al array de hojas
        hojas.push({ idhoja: sheetId, title, content });
        await updateDoc(userDocRef, { hojas });
      }
    } else {
      // Si el documento del usuario no existe, lo creamos
      await setDoc(userDocRef, {
        idUser: userId,
        hojas: [{ idhoja: sheetId, title, content }],
      });
    }
  } catch (error) {
    console.error("Error al guardar la hoja del usuario:", error);
    throw error;
  }
};

// Suscribirse a los cambios en un documento de usuario
export const subscribeToUserSheets = (userId, callback) => {
  try {
    const userDocRef = doc(db, "workspaces", userId);
    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        callback(snapshot.data());
      } else {
        callback(null);
      }
    });
    return unsubscribe;
  } catch (error) {
    console.error("Error al suscribirse a los cambios del usuario:", error);
    throw error;
  }
};

export const getAllUserSheets = async (userId) => {
  try {
    const userDocRef = doc(db, "workspaces", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const hojas = userDoc.data().hojas || [];
      return hojas;
    } else {
      throw new Error("Documento del usuario no encontrado");
    }
  } catch (error) {
    console.error("Error al obtener las hojas del usuario:", error);
    throw error;
  }
};

export const deleteUserSheet = async (userId, sheetId) => {
  try {
    const userDocRef = doc(db, "workspaces", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      let hojas = userDoc.data().hojas || [];
      hojas = hojas.filter((hoja) => hoja.idhoja !== sheetId);
      await updateDoc(userDocRef, { hojas });
    } else {
      throw new Error("Documento del usuario no encontrado");
    }
  } catch (error) {
    console.error("Error al eliminar la hoja del usuario:", error);
    throw error;
  }
};

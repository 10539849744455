import Swal from "sweetalert2";
import logo from "../assets/logo.png";
import loading from "../assets/loading.svg";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Home({ logged }) {
  const navigate = useNavigate();
  const Section = ({ title, color, children }) => (
    <Box marginBottom={3}>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        sx={{ color, fontWeight: "bold" }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );

  const SkillCard = ({ title, description }) => (
    <Card sx={{ backgroundColor: "#ff2e63", color: "#f5f6fa" }}>
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
    </Card>
  );

  const ProjectCard = ({ title, description, image, githubLink, liveLink }) => (
    <Card sx={{ backgroundColor: "#2c2c54", color: "#f5f6fa" }}>
      <CardMedia component="img" alt={title} height="140" image={image} />
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <Button size="small" href={githubLink} sx={{ color: "#08d9d6" }}>
        Ver en GitHub
      </Button>
      <Button size="small" href={liveLink} sx={{ color: "#08d9d6" }}>
        Ver en Vivo
      </Button>
    </Card>
  );

  const skills = [
    {
      title: "Frontend",
      description:
        "React, Material UI (MUI), Bootstrap, jQuery, JavaScript (JS), HTML, CSS",
    },
    { title: "Backend", description: "Node.js, Express.js" },
    { title: "Bases de Datos", description: "Firebase, MongoDB" },
    {
      title: "Otras Habilidades",
      description: "Cpanel, Git, GitHub, puesta en marcha de proyectos",
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: 0,
        height: "100vh", // Ocupa toda la altura disponible
        display: "flex",
        background: {
          xs: "linear-gradient(to top, #f5f6fa 50%, #ff2e63 50%)",
          sm: "linear-gradient(to right, #ff2e63 50%, #f5f6fa 50%)",
        },
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      {/* Botón fijo para ir a AlienazNotes */}
      {!logged && (
        <Box
          sx={{
            position: { xs: "none", md: "fixed" },
            marginTop: { xs: "10px", md: "none" },
            marginLeft: { xs: "10px", md: "none" },
            top: 10,
            left: 10,
            zIndex: 1000,
            width: "270px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            color="botonOscuro"
            onClick={() => {
              navigate("/login");
            }}
          >
            Login alienazNotes
          </Button>
          <Button
            variant="contained"
            color="botonOscuro"
            onClick={() => {
              navigate("/register");
            }}
          >
            Registrarse
          </Button>
        </Box>
      )}

      {/* Sección Izquierda (Banner) */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          padding: 2,
          backgroundColor: "#ff2e63",
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          sx={{ fontWeight: "bold", fontSize: "2rem" }}
        >
          Alienaz
        </Typography>
        <Box
          component="img"
          src={logo}
          alt="logo"
          sx={{ width: 200, marginBottom: 2 }}
        />
        <Typography
          variant="h2"
          component="h1"
          sx={{ fontWeight: "bold", fontSize: "4rem" }}
        >
          HELLO.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginTop: 1,
            writingMode: { xs: "horizontal-tb", sm: "vertical-rl" },
            transform: { sm: "rotate(180deg)" },
            opacity: 0.7,
            overflow: "hidden",
            whiteSpace: "nowrap",
            animation: {
              xs: "scrollText 10s linear infinite",
              sm: "none",
            },
          }}
        >
          Héctor San Martín - Desarrollador Web Fullstack
        </Typography>
      </Box>

      {/* Sección Derecha */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          color: "#2c2c54",
          padding: 3,
          overflowY: "auto", // Habilita scroll vertical para el contenido
          backgroundColor: "#f5f6fa",
        }}
      >
        <Section title="Sobre mí" color="#ff2e63">
          <Typography variant="body1">
            Soy Alienaz, un desarrollador web fullstack con 2 años de
            experiencia creando aplicaciones web modernas y funcionales. Me
            especializo en el desarrollo con React, Material UI, y Firebase,
            siempre enfocado en ofrecer una experiencia de usuario intuitiva y
            eficiente.
          </Typography>
        </Section>

        <Section title="Habilidades" color="#ff2e63">
          <Grid container spacing={2}>
            {skills.map((skill, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <SkillCard
                  title={skill.title}
                  description={skill.description}
                />
              </Grid>
            ))}
          </Grid>
        </Section>

        <Section title="Proyectos Destacados" color="#ff2e63">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <ProjectCard
                title="Aplicación de Gestor de Tareas"
                description="Aplicación para crear, gestionar y categorizar tareas diarias,
                desarrollada con React y Firebase para el manejo de autenticación
                y base de datos en tiempo real."
                image={loading}
                githubLink="#"
                liveLink="#"
              />
            </Grid>
          </Grid>
        </Section>

        <Section title="Contacto" color="#ff2e63">
          <Typography variant="body1">
            Si estás interesado en trabajar conmigo o simplemente deseas conocer
            más acerca de mis proyectos, no dudes en contactarme.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#ff2e63",
              color: "#f5f6fa",
              marginTop: 2,
            }}
            onClick={() =>
              Swal.fire(
                "¡Gracias!",
                "Me pondré en contacto contigo lo antes posible.",
                "success"
              )
            }
          >
            Enviar Mensaje
          </Button>
        </Section>
      </Box>
      <style>
        {`
          @keyframes scrollText {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </Container>
  );
}

import React, { useState } from "react";
import {
  Button,
  TextField,
  Link,
  Box,
  Grid,
  Typography,
  ThemeProvider,
  createTheme,
  InputAdornment,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../database/config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../actions/auth_actions";
import logoOtherColor from "../assets/logo.png";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (userData, userId) => {
    dispatch(login(userData, userId));
  };

  const onLogin = async (e) => {
    e.preventDefault();

    // Validación de campos vacíos
    if (!email.trim() || !password.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: "Por favor, rellena todos los campos.",
      });
      return;
    }

    // Mostrar modal de carga
    Swal.fire({
      imageUrl: logoOtherColor,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      background: "#2f2d33",
    });

    try {
      // Intentar iniciar sesión
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email.trim(),
        password.trim()
      );

      // Verificar si el correo del usuario está verificado
      if (!userCredential.user.emailVerified) {
        Swal.close();
        Swal.fire({
          icon: "warning",
          title: "Correo no verificado",
          text: "Por favor, verifica tu correo. Recuerda revisar la carpeta de spam.",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "¡Inicio de sesión exitoso!",
          text: "Bienvenido de nuevo.",
        });

        // Aquí puedes realizar acciones adicionales, como navegar o cargar datos del usuario.
        const user = userCredential.user;
        handleLogin({ email: user.email }, user.uid); // Dispatch login
        navigate("/workSpace"); // Redirige al usuario al home o a la página que desees
      }
    } catch (error) {
      Swal.close(); // Cierra el modal de carga antes de mostrar el error
      // Manejar errores específicos de Firebase
      if (error.code === "auth/wrong-password") {
        Swal.fire({
          icon: "error",
          title: "Contraseña incorrecta",
          text: "Por favor, verifica tu contraseña.",
        });
      } else if (error.code === "auth/user-not-found") {
        Swal.fire({
          icon: "error",
          title: "Usuario no encontrado",
          text: "El correo ingresado no está registrado.",
        });
      } else if (error.code === "auth/too-many-requests") {
        Swal.fire({
          icon: "error",
          title: "Demasiados intentos fallidos",
          text: "Por favor, inténtalo más tarde.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al iniciar sesión",
          text: "Ocurrió un problema inesperado. Intenta de nuevo más tarde.",
        });
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1f1f1f",
        height: "100vh",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={11} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={logoOtherColor}
              sx={{ width: "180px", height: "180px", marginTop: 1 }}
            />
          </Box>

          <Box
            component="form"
            noValidate
            onSubmit={onLogin}
            sx={{ mt: 1, width: "100%" }}
          >
            <TextField
              sx={{ mb: 2 }}
              margin="normal"
              required
              fullWidth
              placeholder="Correo"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                style: {
                  padding: "3%",
                },
              }}
              inputProps={{
                style: {
                  height: "90%",
                },
              }}
            />
            <TextField
              sx={{ mb: 2 }}
              margin="normal"
              required
              fullWidth
              placeholder="Contraseña"
              type="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                style: {
                  padding: "3%",
                },
              }}
              inputProps={{
                style: {
                  height: "90%",
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                height: "48px",
                background: "#006686",
                color: "#77F8E0",
                borderRadius: "25px",
                ":hover": { background: "#77F8E0", color: "#006686" },
              }}
            >
              Iniciar sesión
            </Button>
            {/* <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Link
                  style={{ textDecoration: "none" }}
                  href="/recoverPass"
                  variant="body2"
                  sx={{ color: "#E1E0FF" }}
                >
                  ¿Olvidaste tu cuenta?
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Grid,
  Divider,
  IconButton,
  Box,
} from "@mui/material";
import { LogoutRounded, Add } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/auth_actions";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../assets/logo.png";
import FireNav from "./fire_nav";
import { signOut } from "firebase/auth";
import { db, auth } from "../database/config";
import { doc, onSnapshot, updateDoc, getDoc } from "firebase/firestore";
import { saveUserSheet } from "../database/workSpace";
import Swal from "sweetalert2";
import loading from "../assets/loading.svg";
import CustomThemeProvider, {
  useThemeToggle,
} from "../components/customThemeProvider"; // Importar el CustomThemeProvider
import AdbIcon from "@mui/icons-material/Adb";

const MyDrawer = ({
  mobileOpen,
  handleDrawerToggle,
  drawerWidth,
  handleDrawerToggleDesktop,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth.idUser);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [availableSheets, setAvailableSheets] = useState([]);
  const toggleTheme = useThemeToggle(); // Hook para alternar el modo de tema

  useEffect(() => {
    if (!userId) return;

    const userDocRef = doc(db, "workspaces", userId);
    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        setAvailableSheets(data.hojas || []);
      } else {
        setAvailableSheets([]);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const handleToggleDrawer = () => {
    handleDrawerToggleDesktop();
    setDrawerOpen((prevOpen) => !prevOpen);
    handleDrawerToggle();
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      dispatch(logout());
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleSheetSelect = (sheet) => {
    navigate(`/workSpace/${sheet.idhoja}`);
  };
  const generateDynamicId = () => {
    const timestamp = Date.now(); // Tiempo actual en milisegundos
    const randomNum = Math.floor(Math.random() * 1000000); // Número aleatorio de 6 dígitos
    return `sheet-${timestamp}-${randomNum}`; // Combina ambos para formar un ID único
  };

  const handleAddNewSheet = async () => {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 400,
      imageHeight: 400,
      title: "Creando nueva hoja",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      background: "#504a53",
      color: "green",
    });

    try {
      // Validar que el usuario esté autenticado
      if (!userId) throw new Error("Usuario no autenticado.");

      // Validar el documento del usuario en Firestore
      const userDocRef = doc(db, "workspaces", userId);
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists()) {
        throw new Error("El documento del usuario no existe en Firestore.");
      }

      // Generar un ID dinámico único
      const newId = generateDynamicId();

      const newSheet = {
        idhoja: newId,
        title: "Nueva hoja sin título",
        content: {
          type: "doc",
          content: [],
        },
      };

      const existingSheets = userDocSnapshot.data()?.hojas || [];

      // Actualizar Firestore con la nueva hoja
      await updateDoc(userDocRef, {
        hojas: [...existingSheets, newSheet],
      });

      Swal.close();
      navigate(`/workSpace/${newSheet.idhoja}`);
    } catch (error) {
      Swal.fire("Error", "Hubo un problema al crear la hoja", "error");
      console.error("Error al guardar la nueva hoja en Firestore:", error);
    }
  };

  return (
    <CustomThemeProvider>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "16px",
            }}
          >
            <a href="/workSpace" style={{ width: "90%" }}>
              <img
                src={Logo}
                alt="Logo"
                width="100%"
                style={{ cursor: "pointer" }}
              />
            </a>
          </Box>

          <Divider sx={{ marginY: "8px" }} />

          <Grid container spacing={1} sx={{ flexGrow: 1 }}>
            <Grid item xs={12}>
              <ListItem
                button
                onClick={handleAddNewSheet}
                sx={{
                  width: "100%", // Asegura que ocupe todo el ancho del contenedor
                  padding: "12px", // Define el mismo padding para consistencia
                  borderRadius: "8px", // Un borde uniforme si se necesita
                }}
              >
                <ListItemIcon>
                  <Add sx={{ color: "greenyellow" }} />
                </ListItemIcon>
                <ListItemText primary="Nueva hoja" sx={{ color: "white" }} />
              </ListItem>
            </Grid>
          </Grid>
          <Box sx={{ height: "100%", overflowY: "auto" }}>
            {availableSheets.map((sheet) => (
              <Grid item key={sheet.idhoja} xs={12}>
                <ListItem
                  button
                  onClick={() => handleSheetSelect(sheet)}
                  sx={{
                    width: "100%", // Asegura que tenga el mismo ancho
                    padding: "12px", // Mismo padding que el botón de 'Añadir nueva hoja'
                    borderRadius: "8px", // Un borde similar para unificar el estilo
                    color: "white",
                  }}
                >
                  <ListItemText
                    primary={sheet.title || `Hoja ${sheet.idhoja}`}
                  />
                </ListItem>
              </Grid>
            ))}
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              backgroundColor: "#2f2d33",
              paddingY: "8px",
            }}
          >
            <Divider />
            <Grid container>
              <Grid item xs={12}>
                <FireNav>
                  <ListItem
                    button
                    onClick={() => {
                      navigate("/home");
                    }}
                    sx={{
                      color: "#BA1A1A",
                      borderRadius: "10px",
                    }}
                  >
                    <ListItemIcon>
                      <AdbIcon sx={{ color: "#f1f1f1" }} />
                    </ListItemIcon>
                    <ListItemText primary="Alienaz" />
                  </ListItem>
                </FireNav>
              </Grid>
              <Grid item xs={12}>
                <FireNav>
                  <ListItem
                    button
                    onClick={handleLogout}
                    sx={{
                      color: "#BA1A1A",
                      borderRadius: "10px",
                    }}
                  >
                    <ListItemIcon>
                      <LogoutRounded sx={{ color: "#ff0000" }} />
                    </ListItemIcon>
                    <ListItemText primary="Cerrar sesión" />
                  </ListItem>
                </FireNav>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      <IconButton
        color="primary"
        aria-label="open drawer"
        edge="start"
        onClick={handleToggleDrawer}
        sx={{
          mr: 2,
          position: "fixed",
          zIndex: 1301,
          top: "10px",
          left: drawerOpen ? `15px` : "20px",
          transition: "left 0.3s",
        }}
      >
        <MenuIcon />
      </IconButton>
    </CustomThemeProvider>
  );
};

export default MyDrawer;
